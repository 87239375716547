import React, { useMemo, useState } from "react";

import { useMediaQuery } from "react-responsive";

import { IProductItem } from "../../../common/interfaces/products.interface";
import { useAppSelector } from "../../../store/store";
import { WizardScrollableContainer } from "../../wizards/common/WizardScrollableContainer";
import { InputGroup } from "../InputGroup";
import { Loader } from "../Loader";
import { ProductRowItems } from "../products/ProductRowItems";
import { SelectField, SelectOption } from "../SelectField";

interface Props {
  selectedProduct: IProductItem | null;
  onSelect: (item: IProductItem) => void;
  error?: any;
  hidePrice: boolean;
  scrollableContainerGap?: number;
}

export const ProductTypesList: React.FC<Props> = ({
  scrollableContainerGap,
  hidePrice,
  selectedProduct,
  onSelect,
  error
}) => {
  const isSmallDesktop = useMediaQuery({
    query: "(max-width: 1350px)"
  });

  const isVerySmallDesktop = useMediaQuery({
    query: "(max-width: 1000px)"
  });

  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<SelectOption | null>(null);
  const { data: products, loader } = useAppSelector((state) => state.productTypes.productList);

  const filteredProducts = useMemo(() => {
    const filterProducts = (product: IProductItem) => {
      const isMatchingCategory = !selectedCategory || product.categoryId.toString() === selectedCategory.value;
      const isMatchingSearchInput = !searchInput || product.title.toLowerCase().includes(searchInput.toLowerCase());

      return isMatchingCategory && isMatchingSearchInput;
    };

    return {
      top: products.top.filter(filterProducts),
      all: products.all.filter(filterProducts)
    };
  }, [products, selectedCategory, searchInput]);

  const productColSize = useMemo(() => {
    if (isVerySmallDesktop) return 6;
    if (isSmallDesktop) return 4;

    return 3;
  }, [isSmallDesktop, isVerySmallDesktop]);

  const categories = useMemo(() => {
    return products.categories.map((e) => {
      const isDisabled = products.all.filter((p) => p.categoryId === e.id).length === 0;
      return {
        label: `${isDisabled ? "[COMING SOON] " : ""}${e.title}`,
        value: e.id.toString(),
        disabled: isDisabled
      };
    });
  }, [products]);

  const renderItems = (items: IProductItem[]) => {
    return (
      <ProductRowItems
        lgImage
        imageHoverable
        hidePrice={hidePrice}
        items={items}
        colSize={productColSize}
        selectable
        selectedValue={selectedProduct ?? null}
        renderTooltip={(item) => {
          return <span dangerouslySetInnerHTML={{ __html: item.description }} />;
        }}
        onSelect={onSelect}
      />
    );
  };

  return (
    <>
      {loader && <Loader />}
      <p className="wizard-step__description">
        To start, please select your product category or choose from already listed products.
      </p>

      <div className="row">
        <div className="col-12">
          <InputGroup
            className="wizard-step__search mt-4"
            prependAddon={
              <span className="input-group-text bg-white">
                <i className="bx bx-search font-size-18" />
              </span>
            }
            input={(p) => (
              <input
                type="text"
                className="form-control border-start-0"
                placeholder="Search..."
                aria-label="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                {...p}
              />
            )}
          />
          <h5 className="mt-4 mb-3">Choose product category</h5>

          <SelectField
            options={categories}
            placeholder="Select category"
            required
            value={selectedCategory}
            onChange={(data) => {
              setSelectedCategory(data);
            }}
          />
        </div>
      </div>
      <WizardScrollableContainer error={error} containerGap={scrollableContainerGap}>
        {products.top.length > 0 ? (
          <>
            <h5>Top products</h5>
            {renderItems(filteredProducts.top.map((e) => ({ ...e })))}
          </>
        ) : (
          ""
        )}

        <h5>All products</h5>

        {renderItems(filteredProducts.all.map((e) => ({ ...e })))}
      </WizardScrollableContainer>
    </>
  );
};
